import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Box } from '../components/Box';
import { Heading } from '../components/Heading';
import { Text } from '../components/Text';

function NotFound() {
  return (
    <Box as="main">
      <Box height="100vh" width="100vw">
        <StaticImage
          src="../images/404.png"
          alt=""
          placeholder="blurred"
          objectFit="cover"
          loading="eager"
          draggable={false}
          style={{ height: '100%' }}
          imgStyle={{ height: '100%' }}
        />
      </Box>
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height="100vh"
        width="100vw"
        zIndex={10}
      >
        <Heading
          level={1}
          fontSize="40vmin"
          fontWeight={700}
          color="var(--white)"
          lineHeight={1}
        >
          NEIN
        </Heading>
        <BackLink to="/">Back to the site</BackLink>
      </Box>
    </Box>
  );
}

const BackLink = styled(Link)`
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 5px;
  color: var(--white);
`;

export default NotFound;
